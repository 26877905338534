<template>
  <div>
    <VueMultiselect
      v-model="value"
      :options="options"
      :close-on-select="true"
      :clear-on-select="false"
      placeholder="Selecione..."
      :loading="isLoading"
      label="name"
      track-by="id"
    ></VueMultiselect>
  </div>
</template>

<script>
import VueMultiselect from "vue-multiselect";
import { mapActions, mapState } from "vuex";

export default {
  props: ["nameField", "data"],
  emits: ["onChange"],
  data() {
    return {
      value: null,
      options: [],
    };
  },
  components: {
    VueMultiselect,
  },
  watch: {
    nameField: function (newValue) {
      this.setValue(newValue);
    },
    value: function (newValue) {
      this.onSelect(newValue);
    },
    data(newValue) {
      this.getOptions(newValue);
    },
  },
  mounted() {
    this.getOptions(this.data);
  },
  computed: {
    ...mapState(["isLoading"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    onSelect(selectedOption) {
      if (selectedOption) {
        this.$emit("onChange", selectedOption.id);
      } else {
        this.$emit("onChange", null);
      }
    },
    setValue(value) {
      if (value) {
        if (Object.prototype.hasOwnProperty.call(value, "id")) {
          this.value = this.options.find((el) => el.id == value.id);
        } else {
          this.value = this.options.find((el) => el.id == value);
        }
      } else {
        this.value = this.options.find((el) => el.id == null);
      }
      this.onSelect(this.value);
    },
    getOptions(data) {
      this.setLoading(true);
      this.options = data.map((el) => {
        return { id: el._id, name: el.name };
      });
      this.setValue(this.nameField);
      this.setLoading(false);
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
