<template>
  <Layout :pagetitle="'Produtos'">
    <div class="col-12">
      <div class="card">
        <div class="card-body">
          <b-form-row class="">
            <div class="col-12 col-lg-2 mb-2 mb-lg-0">
              <label class="form-label">Plataforma</label>
              <StandardSelector
                @onChange="onchangePlatform"
                :nameField="filters.idPlatform"
                :data="platforms"
              />
            </div>

            <div class="col-12 col-lg-2 mb-2 mb-lg-0">
              <label class="form-label">Categoria</label>
              <StandardSelector
                @onChange="onchangeCategory"
                :nameField="filters.idCategory"
                :data="categories"
              />
            </div>

            <div class="col-12 col-lg-2 mb-2 mb-lg-0">
              <label class="form-label">Status</label>
              <StandardSelector
                @onChange="onchangeStatus"
                :nameField="filters.status"
                :data="dataStatus"
              />
            </div>

            <div class="col-12 col-lg-2 mb-2 mb-lg-0">
              <label class="form-label">Ordem</label>
              <StandardSelector
                @onChange="onchangeOrder"
                :nameField="filters.order"
                :data="dataOrder"
              />
            </div>

            <div class="col-12 col-lg-4">
              <label class="form-label">Título</label>
              <input
                type="search"
                class="form-control border-1 bg-light"
                placeholder="Buscar..."
                :disabled="isLoading"
                v-model="filters.title"
              />
            </div>
          </b-form-row>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between align-items-center">
            <h4 class="card-title mb-0">Produtos</h4>

            <button
              :class="`btn btn-sm btn-rounded waves-effect waves-light col-3 col-lg-1 btn-${company.color ? company.color : 'primary'}`"
              @click="
                $router.push({
                  name: 'companyNewProduct',
                  params: { slug: $route.params.slug },
                })
              "
            >
              <span>Novo</span>
            </button>
          </div>
          <div class="card-body">
            <div v-if="isLoading" class="d-flex justify-content-center">
              <div :class="`spinner-border m-1 text-${company.color ? company.color : 'primary'}`" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div v-else class="table-responsive">
              <table class="table align-middle mb-0">
                <colgroup>
                  <col style="width: 2%" />
                  <col style="width: 5%" />
                  <col style="width: 45%" />
                  <col style="width: 15%" />
                  <col style="width: 12%" />
                  <col style="width: 8%" />
                  <col style="width: 8%" />
                  <col style="width: 5%" />
                </colgroup>
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Ação</th>
                    <th>Título</th>
                    <th class="text-end">Valor</th>
                    <th>Categoria</th>
                    <th>Tipo</th>
                    <th>Plataforma</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(product, index) in productsFiltered" :key="product._id">
                    <th scope="row">{{ index + 1 }}</th>
                    <td>
                      <button
                        type="button"
                        class="btn btn-sm btn-light"
                        @click="
                          $router.push({
                            name: 'companyEditProduct',
                            params: {
                              slug: $route.params.slug,
                              idProduct: product._id,
                            },
                          })
                        "
                      >
                        Editar
                      </button>
                    </td>
                    <td>{{ product.title }}</td>
                    <td class="text-end">
                      {{ product.promoPrice ? $utils.formatMoney(product.promoPrice, false) : $utils.formatMoney(product.price, false) }}
                    </td>
                    <td>{{ getNameCategory(product.idCategory) }}</td>
                    <td>
                      <span :class="`badge-lg p-1 px-2 rounded-pill badge-soft-${product.link ? 'info' : 'primary'}`">
                        {{ product.link ? 'Link' : 'Produto' }}
                      </span>
                    </td>

                    <td>{{ product.link ? getNamePlatform(product.idPlatform) : 'N/A' }}</td>
                    <td>
                      <span :class="`badge-lg p-1 px-2 rounded-pill badge-soft-${product.active ? 'success' : 'danger'}`">
                        {{ product.active ? "Ativo" : "Inativo" }}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="d-flex justify-content-end">
        <button
          :class="`btn btn-rounded waves-effect waves-light col-6 col-md-2 btn-${company.color ? company.color : 'primary'}`"
          @click="$router.push({ name: 'companyPrincipal' })"
        >
          <span>Voltar</span>
        </button>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { mapActions, mapState } from "vuex";
import StandardSelector from "../../../components/common/StandardSelector.vue";

export default {
  data() {
    return {
      company: JSON.parse(localStorage.getItem("company")),
      products: [],
      categories: [],
      platforms: [],
      dataStatus: [
        { _id: "true", name: "Ativo" },
        { _id: "false", name: "Inativo" },
      ],
      dataOrder: [
        { _id: "title", name: "Título" },
        { _id: "data", name: "Data" },
      ],
      filters: {
        idCategory: null,
        idPlatform: null,
        status: null,
        title: null,
        order: null,
      },
    };
  },
  components: {
    Layout,
    StandardSelector,
  },
  computed: {
    ...mapState(["isLoading"]),
    productsFiltered() {
      let filteredProducts = this.products.filter((product) => {
        if (
          (this.filters.idCategory &&
            product.idCategory !== this.filters.idCategory) ||
          (this.filters.idPlatform &&
            product.idPlatform !== this.filters.idPlatform) ||
          (this.filters.status !== null &&
            product.active != JSON.parse(this.filters.status)) ||
          (this.filters.title &&
            !product.title
              .toLowerCase()
              .includes(this.filters.title.toLowerCase()))
        ) {
          return false;
        }
        return true;
      });

      filteredProducts.sort((a, b) => {
        if (a.updatedAt && b.updatedAt) {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        } else if (a.updatedAt) {
          return -1;
        } else if (b.updatedAt) {
          return 1;
        } else {
          return new Date(b.createdAt) - new Date(a.createdAt);
        }
      });

      if (this.filters.order === "title") {
        filteredProducts.sort((a, b) => (a.title > b.title ? 1 : -1));
      }

      return filteredProducts;
    },
  },

  async mounted() {
    if (this.company._id) {
      this.setLoading(true);
      const products = await this.$utils.getByCompany(
        "product",
        this.company._id
      );
      this.products = products;

      try {
        const res = await this.$api.get("platform");
        this.platforms = res.data;
      } catch (error) {
        console.log(error);
      }

      try {
        const res = await this.$api.get("category");
        this.categories = res.data;
      } catch (error) {
        console.log(error);
      }
      this.setLoading(false);
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    getNameCategory(idCategory) {
      const cat = this.categories.find((el) => el._id === idCategory);
      if (cat) return cat.name;
      else return null;
    },
    getNamePlatform(idPlatform) {
      const plat = this.platforms.find((el) => el._id === idPlatform);
      if (plat) return plat.name;
      else return null;
    },
    onchangeCategory(idCategory) {
      this.filters.idCategory = idCategory;
    },
    onchangePlatform(idPlatform) {
      this.filters.idPlatform = idPlatform;
    },
    onchangeStatus(status) {
      this.filters.status = status;
    },
    onchangeOrder(order) {
      this.filters.order = order;
    },
  },
};
</script>

<style scoped></style>
